import qs from 'qs'

// Namespaced reducers to handle action types and payloads

import {
  CLIP_LIST,
  CHANGE_CLIPS,
} from './types'

// Fetch util to allow promises to resolve
// with proper JSON

import { get } from '../libs/request'
import { ROOT_URL } from '../helpers/constants'

export const clipList = (queryObject) => {
  // Get clips from back end
  const queryParams = qs.stringify(queryObject)
  const url = `${ ROOT_URL }clip?${ queryParams }`
  const request = get(url)
  return {
    type: CLIP_LIST,
    payload: request,
  }
}

export const changeClips = (newClips) => (
  {
    type: CHANGE_CLIPS,
    payload: newClips,
  }
)
